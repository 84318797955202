<template>
  <div
    class="other-login ac"
    v-if="isPc || ccMobileShow">
    <el-image
      :id="item.toLowerCase()+'Btn'"
      :class="['cursor',item.toLowerCase()+'Btn']"
      :src="loadingItem === item ? thirdPartyImg.loading : thirdPartyImg[item]"
      @click="thirdLogin(item)"
      v-for="(item,index) in thirdPartyList"
      :key="index"></el-image>
  </div>
</template>

<script>
import { Loading } from 'element-ui'
import { getUrlParam } from '@/assets/js/utils'
import Bus from '@/assets/js/vueBus'
import { PublicClientApplication } from '@azure/msal-browser'
import { mapState } from 'vuex'
export default {
  name: 'OtherLogin',
  data () {
    return {
      thirdPartyImg: {
        FaceBook: require('@/assets/images/facebook.svg'),
        Google: require('@/assets/images/google.svg'),
        Auth0: require('@/assets/images/auth0.svg'),
        Microsoft: require('@/assets/images/microsoft.svg'),
        Wechat: require('@/assets/images/wx.svg'),
        Apple: require('@/assets/images/apple.svg'),
        loading: require('@/assets/images/background.svg')
      },
      loadingItem: null,
      loadingObj: null,
      domainName: '',
      ccMobileShow: false,
      thirdPartyList: []
    }
  },
  computed: {
    socialLogin () {
      return this.$store.state.socialLogin
    },
    isPc () {
      return this.$store.state.isPc
    },
    ...mapState({
      httpsFlag: 'httpsFlag',
      tagKeys: 'tagKeyList',
      showPopup: 'showPopup',
      googleClientId: 'googleClientId',
      googleRedirectUrl: 'googleRedirectUrl',
      appleRedirectUrl: 'appleRedirectUrl',
      appleClientId: 'appleClientId',
      clearCookiePathList: 'clearCookiePathList'
    })
  },
  created () {
    const localUrl = window.location.hostname
    const str1 = localUrl.split('.')[1]
    const str2 = localUrl.split('.')[2]
    this.domainName = `.${str1}.${str2}`
    const u = navigator.userAgent
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
    // 目前只有ccmobie显示Google或者Apple登录
    // ccmobile安卓和ios端都显示Google登录, ccmobile只有ios端显示Apple登录
    this.ccMobileShow = getUrlParam('type') === 'tvuccmobile'
    if (this.ccMobileShow) {
      if (isAndroid) {
        this.thirdPartyList = ['Google']
      } else {
        this.thirdPartyList = ['Google', 'Apple']
      }
    } else {
      this.thirdPartyList = this.socialLogin
    }
  },
  methods: {
    // 需要在登录或者注册之前清除cookie里的SID(不同path下的SID)
    clearPopupCookie () {
      const exp = new Date()
      exp.setTime(exp.getTime() - 1)
      this.clearCookiePathList.forEach((item) => {
        window.document.cookie = `SID=;path=${item};domain=${this.domainName};expires=${exp.toGMTString()};${this.httpsFlag ? 'Secure=true;' : ''}`
        window.document.cookie = `SID=;path=${item};domain=${this.domainName};expires=${exp.toGMTString()}`
      })
    },
    // 清除tagkey
    clearTagKey () {
      const exp = new Date()
      exp.setTime(exp.getTime() - 1)
      this.tagKeys.forEach((item) => {
        window.document.cookie = `${item}=;path=/;domain=${this.domainName};expires=${exp.toGMTString()}`
      })
    },
    wxLogin () {
      this.$router.push('/qrcode')
    },
    thirdLogin (item) {
      this.loadingItem = item // 设置为加载中
      this.loadingObj = Loading.service({
        target: document.querySelector('.' + item.toLowerCase() + 'Btn'),
        fullscreen: false,
        body: false,
        spinner: 'el-icon-loading',
        background: 'transparent',
        customClass: this.thirdPartyList.length > 4 ? 'customLoading' : 'customImage'
      })
      this.clearPopupCookie()
      switch (item) {
        case 'FaceBook':
          this.fbLogin()
          break
        case 'Auth0':
          this.auth0Login()
          break
        case 'Google':
          this.ggLogin()
          break
        case 'Microsoft':
          this.microsoftLogin()
          break
        case 'Wechat':
          this.wxLogin()
          break
        case 'Apple':
          this.appleLogin()
          break
        default:
          break
      }
    },
    // 微软登录
    microsoftLogin () {
      const that = this
      const id = localStorage.getItem('microsoftClientId')
      const config = {
        auth: {
          clientId: id // defaults to application start page
        }
      }

      const loginRequest = {
        scopes: ['User.ReadWrite']
      }

      const myMsal = new PublicClientApplication(config)

      myMsal.loginPopup(loginRequest)
        .then(function (loginResponse) {
          that.fetchMicrosoftInfo(loginResponse.accessToken, loginResponse.account.username)
        }).catch(function () {
          // login failure
          that.loadingObj.close()
          that.loadingItem = null
        })
    },
    fetchMicrosoftInfo (token, name) {
      this.$axios.post('/userGroup/user-group/login/microsoft',
        {
          email: name,
          accessToken: token,
          serverName: getUrlParam('serviceName'),
          redirectUrl: getUrlParam('url')
        }
      ).then((res) => {
        if (res.data.errorCode == '0x0') {
          this.afterThirdParty(res)
        } else if (res.data.errorCode == '80943007') {
          Bus.$emit('googleChange', true)
        } else {
          this.$message({
            message: res.data.errorInfo,
            type: 'error',
            duration: 3000
          })
        }
        this.loadingObj.close()
        this.loadingItem = null
      }).catch(() => {
        this.loadingObj.close()
        this.loadingItem = null
      })
    },
    auth0Login () {
      this.$axios.get('/userGroup/user-group/login/auth0/redirect', {}).then((res) => {
        this.loadingObj.close()
        this.loadingItem = null
        if (res.data.errorCode == '0x0') {
          // media hub弹窗内登录成功之后，先将tagkey清除，再存储
          // if (this.showPopup) {
          //   this.clearTagKey()
          // }
          window.top.location.href = res.data.result
        }
      }).catch(() => {
        this.loadingObj.close()
        this.loadingItem = null
      })
    },
    ggLogin () {
      // tvuccmobile安卓app端第三方登录的时候向安卓端发送通知
      if (this.ccMobileShow) {
        if (window.Android) {
          Android.GoogleLogin('')
        } else {
          // ios机型
          window.webkit.messageHandlers.GoogleLogin.postMessage('')
        }
        setTimeout(() => {
          this.loadingObj.close()
          this.loadingItem = null
        }, 2000)
        return
      }
      const client_id = this.googleClientId
      const redirect_uri = this.googleRedirectUrl
      // const redirect_uri = 'https://cc-ms-dev.tvunetworks.com/userGroup/user-group/login/v2/google' // 本地调试
      const scope = 'email profile'
      // const response_type = 'token'
      const response_type = 'code'
      let stateParam = ''
      if (getUrlParam('serviceName')) {
        const serverName = getUrlParam('serviceName')
        const url = getUrlParam('url')
        const stateValue = JSON.stringify({ serverName: serverName, url: url })
        stateParam = encodeURIComponent(btoa(stateValue)) // 编码以确保URL安全
      }
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${client_id}&redirect_uri=${encodeURIComponent(redirect_uri)}&scope=${encodeURIComponent(scope)}&response_type=${response_type}&prompt=select_account&state=${stateParam}`

      // 重定向到Google登录页面
      window.top.location.href = authUrl

      setTimeout(() => {
        this.loadingObj.close()
        this.loadingItem = null
      }, 2000)

      // const that = this
      // if (!gapi.auth2) {
      //   that.$message.error(that.$t('googleErrorTips'))
      //   that.loadingObj.close()
      //   return
      // }
      // if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
      //   gapi.auth2.getAuthInstance().signOut().then(function () {
      //     that.ggLogin()
      //   })
      // } else {
      //   gapi.auth2.getAuthInstance().signIn({
      //     scope: 'profile email'
      //   }).then(function (resp) {
      //     // that.loadingObj.close();
      //     that.fetchGoogleInfo(resp.getBasicProfile(), resp.getAuthResponse(true))
      //   }).catch(({ error }) => {
      //     that.loadingObj.close()
      //     if (error == 'popup_closed_by_user') {
      //       // 手动关闭登录框
      //     } else if (error == 'access_denied') {
      //       // 取消授权异常
      //     }
      //   })
      // }
    },
    fetchGoogleInfo (userInfo, authInfo) {
      this.$axios.post('/userGroup/user-group/login/google',
        {
          email: userInfo.getEmail(),
          accessToken: authInfo.id_token,
          serverName: getUrlParam('serviceName'),
          redirectUrl: getUrlParam('url')
        }
      ).then((res) => {
        this.afterThirdParty(res)
      }).catch(() => {
        this.loadingObj.close()
        this.loadingItem = null
      })
    },
    appleLogin () {
      let stateParam = ''
      if (getUrlParam('serviceName')) {
        const serverName = getUrlParam('serviceName')
        const url = getUrlParam('url')
        const stateValue = JSON.stringify({ serverName: serverName, url: url })
        stateParam = encodeURIComponent(btoa(stateValue)) // 编码以确保URL安全
      }
      if (getUrlParam('type')) {
        const type = getUrlParam('type')
        const pid = getUrlParam('pid')
        const stateValue = JSON.stringify({ type: type, pid: pid })
        stateParam = encodeURIComponent(btoa(stateValue)) // 编码以确保URL安全
      }
      // 初始化Apple ID认证
      AppleID.auth.init({
        clientId: this.appleClientId || 'com.tvunetworks.TVUCCMobileServicesID', // 从 Services ID 中获取
        scope: 'email name',
        redirectURI: this.appleRedirectUrl || 'https://cc-ms-dev.tvunetworks.com/userGroup/user-group/login/apple', // 与苹果开发者后台设置的一致
        usePopup: false, // 使用弹出窗口或重定向
        state: stateParam
      })

      setTimeout(() => {
        this.loadingObj.close()
        this.loadingItem = null
      }, 2000)

      // 进行登录
      AppleID.auth.signIn().then(response => {
        window.console.log('Apple login successful:', response)
        // response 包含 ID Token 等信息
        // 将 ID Token 发给后端进行验证和处理
        // const idToken = response.authorization.id_token
        // this.handleLogin(idToken)
      }).catch(error => {
        window.console.error('Apple login failed:', error)
      })
    },
    // handleLogin (idToken) {
    //   alert('idToken: ' + idToken)
    // },
    fbLogin () {
      const that = this

      FB.getLoginStatus(function (response) {
        that.statusChangeCallback(response)
      })
    },
    statusChangeCallback (loginResponse) {
      // Called with the results from FB.getLoginStatus().
      const that = this
      if (loginResponse.status === 'connected') {
        // Logged into your webpage and Facebook.
        FB.api('/me/permissions', function (permissionResponse) {
          const declined = []
          for (let i = 0; i < permissionResponse.data.length; i++) {
            if (permissionResponse.data[i].status == 'declined' || permissionResponse.data[i].status == 'expired') {
              declined.push(permissionResponse.data[i].permission)
            }
          }
          if (declined.indexOf('email') > -1) {
            FB.logout(function () {
              that.loadingObj.close()
              that.loadingItem = null
            })
          } else {
            that.fetchFBInfo(loginResponse)
          }
        })
      } else {
        // Not logged into your webpage or we are unable to tell.
        FB.login(function (loginResponse) {
          if (loginResponse.status === 'connected') {
            // Logged into your webpage and Facebook.
            FB.api('/me/permissions', function (permissionResponse) {
              const declined = []
              for (let i = 0; i < permissionResponse.data.length; i++) {
                if (permissionResponse.data[i].status == 'declined') {
                  declined.push(permissionResponse.data[i].permission)
                }
              }
              if (declined.indexOf('email') > -1) {
                FB.logout(function () {
                  that.loadingObj.close()
                  that.loadingItem = null
                })
              } else {
                that.fetchFBInfo(loginResponse)
              }
            })
          } else {
            // The person is not logged into your webpage or we are unable to tell.
            that.loadingObj.close()
            that.loadingItem = null
          }
        }, {
          scope: 'email',
          return_scopes: true,
          auth_type: 'rerequest'
        })
      }
    },
    afterThirdParty (res) {
      this.loadingObj.close()
      this.loadingItem = null
      if (res.data.errorCode == '0x0') {
        const data = res.data.result
        // 登录成功存储sid
        localStorage.setItem('email', data.email)
        localStorage.setItem('firstName', data.firstName)
        localStorage.setItem('userName', data.userName)
        localStorage.setItem('session', data.session)
        localStorage.setItem('userservice', data.userservice)
        window.userservice = data.userservice
        // if (this.domainName) {
        //   window.document.cookie = `SID=${data.session};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
        // }
        // media hub弹窗内登录成功之后，先将tagkey清除，再存储
        // if (this.showPopup) {
        // }
        // 登录成功之后，先将tagkey清除，再存储
        this.clearTagKey()
        if (data.tagList && data.tagList.length > 0 && this.$store.state.saveTagCookie) {
          data.tagList.forEach(itm => {
            window.document.cookie = `${itm.tagKey}=${itm.tagValue};path=/;domain=${this.domainName};${this.httpsFlag ? 'secure' : ''}`
          })
        }
        // 此时设置sid有效
        this.$store.commit('changesValidSid', true)
        if (data.loginWelcome == '1') {
          this.$router.push('/welcome')
        } else {
          window.top.location.href = data.redirectUrl
        }
      }
    },
    fetchFBInfo (authResponse) { // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
      const that = this

      FB.api('/me?fields=name,email', function (response) {
        that.$axios.post('/userGroup/user-group/login/facebook',
          {
            email: response.email,
            id: response.id,
            accessToken: authResponse.authResponse.accessToken,
            serverName: getUrlParam('serviceName'),
            redirectUrl: getUrlParam('url')
          }
        ).then((res) => {
          if (res.data.errorCode == '0x0') {
            that.afterThirdParty(res)
          } else if (res.data.errorCode == '80943007') {
            Bus.$emit('googleChange', true)
          } else {
            this.$message({
              message: res.data.errorInfo,
              type: 'error',
              duration: 3000
            })
          }
          that.loadingObj.close()
          that.loadingItem = null
        }).catch(() => {
          that.loadingObj.close()
          that.loadingItem = null
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.other-login {
  display: flex;
  justify-content: center;
}
/deep/.customImage {
  .el-loading-spinner {
    margin-top: -18px;
    i {
      color: #252525;
      font-size: 18px;
    }
  }
}

/deep/.customLoading {
  .el-loading-spinner {
    margin-top: -15px;
    i {
      color: #252525;
      font-size: 14px;
    }
  }
}
</style>
